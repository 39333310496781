.branchTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 800;
    color: #383838;
    font-size: 3rem !important;
}

.ButtonVerde{
    font-family: sans-serif;
    align-items: center;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    width: 30%;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: rgb(61, 178, 92) !important;
    color: rgb(255, 255, 255);
    border: none;
}

.logoWpp{
    margin: 0px 7px;
    cursor: pointer;
}

.branchesAvailable{
    font-weight: bold;
    color: rgb(242, 2, 93);
    padding-bottom: 1%;
    font-size: 15pt;
}

.whatsappBusiness{
    padding: 2% 0;
}

.boxBranches{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    opacity: 1;
    width: 600px;
    height: 250px;
    text-align: center;
    margin-top: 1rem;
    border: 3px solid rgb(255, 252, 252);
    padding: 2rem 1rem 0.5rem;
}

.spaceParagraph {
    margin-top: 15px;
}

.spaceBox {
    margin-top: 20px;
}

.branchesIcons{
    width: 15%;
}

.branchesTextIcons{
    width: 100%;
    font-weight: 400;
    padding-top: 5%;
    font-size: 14pt;
}

.branchesWait{
    font-size: 17pt;
    padding-top: 1%;
}

@media only screen and (max-width: 900px){
    .branchTitle{
        font-size: 2rem !important;
    }

    .ButtonVerde{
        width: 80%;
    }

    .branchesTextIcons{
        font-size: 17px;
        text-align: center;
    }

    .branchesWait{
        font-size: 13pt;
        padding-top: 7%;
    }

}
