.cardBox {
  height: 90%;
  width: 90%;
  margin-bottom: 20px;
}

.gridCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgCard {
  width: 100px;
}

.spaceTypography {
  margin-top: 15px;
}

@media only screen and (max-width: 900px) {  
  .imgCard {
    width: 50px;
  }

  .spaceCard {
    margin-top: 30px;
  }

  .titleArrepentimiento{
    font-size: 20pt !important;
  }
}