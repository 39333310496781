@import url('https://fonts.googleapis.com/css2?family=Coming+Soon&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@300;400;500;600;700&display=swap');

.ocultar{
  display: none;
}

.ButtonMagenta {
  background-color: #DA0067 !important;
  border-radius: 35px !important;
  margin: 0;
  padding: 5px 15px;
  color: #FFFFFF;
  text-transform: none;
  font-family: 'Open Sans' !important; 
  font-size: 14px;
  font-weight: 800;
}

.ButtonBlog{
  font-family: sans-serif;
  align-items: center;
  opacity: 1;
  border-radius: 35px !important;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  width: 40%;
  box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
  background-color: white !important;
  color: #383838 !important;
  border: 2px solid !important;
  border-color: #DA0067 !important;
}

.buttonMoreInfo {
  width: 220px;
}

.ButtonYellow {
  background-color: #FFA500 !important;
}

.content img {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins' !important; 
}

.content {
  padding: 20px;
  text-align: left;
}

p {
  font-family: 'Open Sans' !important; 
}

span {
  font-family: 'Open Sans'; 
}

.marginBottomQuestions {
  margin-bottom: 2%;
}

.marginBottomLastQuestion {
  margin-bottom: 60px;
}

.paddingBottomLastQuestion {
  padding-bottom: 60px;
}

.titleBanner{
  font-size: 3rem !important;
}

.lettersWhite {
  color: white;
  width: 79%;
}

.lettersWhiteOnly {
  color: white;
}

.lettersMagenta {
  color: #DA0067 !important;
}

.lettersMagentaNew {
  color: #DA0067 !important;
}

.lettersMagentaVersionThree {
  color: #D50067 !important;
}

.lettersGrey {
  color: #383838 !important;
}

.lettersVersionTwo {
  color: #585858 !important;
}

.lettersGreyParapgraph {
  color: #383838 !important;
}

.backgroundMagenta {
  background-color: #DF1365 !important;
}

.backgroundWhite {
  background-color: white !important;
}

.backgroundYellow {
  background-color: #FAB719 !important;
}

.spaceMedium {
  margin-top: 40px;
}

.textBannerHome {
  padding-top: 3%;
}

.textLeft {
  text-align: left;
}

.boxBannerHome {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxCalculatorHome {
  background-image: url("../images/home/bannerHome.svg");
  width: 100%;
  height: 570px;
  background-position: center;
  /* background-repeat: no-repeat;
  background-size: cover; */
}

.marginTitleHome {
  padding-top: 90px;
}

.titleBannerHome {
  font-weight: 900;
}

.boxTitleHome {
  width: 600px;
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.boxSubtitleHome {
  width: 450px;
  margin-top: 15px;
}

.gridHome {
  text-align: left;
}

.bannerHomePhysicalCommerce {
  text-align: left;
  width: 100%;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
}

.circleBox {
  background-color: #FFDFA5;
  border-radius: 300px;
  width: 276px;
}

.boxForWhat {
  height: 260px;
  border-radius: 42px;
  padding: 10px;
}

.boxWhyUs {
  background-image: url("../images/home/fondo-onda-signo-01.svg");
  height: 640px;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.boxBannerBnpl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding-top: 7%;
}

.boxSmallTypography {
  width: 30%;
  margin-top: 10px;
}

.boxMediumTypography {
  width: 70%;
  text-align: center;
}

.boxHomeBnpl {
  width: 80%;
}

.boxHomeWc {
  width: 90%;
}

.boxTextBnpl {
  width: 90%;
  text-align: left;
  margin-top: 2%;
}

.boxBannerButtonBnpl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexCenterUp {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.flexStartVertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexStartVerticalCalculator {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.textTakeCredit {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
}

.flexWhatNeed {
  display: flex;
  align-items: center;
}

.flexCircleHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flexLastCircleHome {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.boxBnpl {
  background-color: #FFDFA5;
  height: 420px;
}

.boxWc {
  background-color: #F9CFD4;
  height: 420px;
}

.buttonBannerHomePosition {
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
}

.typographyTitles {
  font-size: 64px;
  font-weight: 900;
}

.boxRequirements {
  background-color: #F3F3F3;
  height: 240px;
  text-align: left;
  border-radius: 30px;
  padding-top: 30px;
  padding: 10px;
}

.imgCheckListPaper {
  width: 360px;
}

.imgFaceBiometry {
  width: 360px;
}

.imgOkeyHand {
  width: 270px;
}

.flexImage {
  display: flex;
  justify-content: center;
}

.widthBoxTypography {
  width: 100%;
}

.imgMoto {
  width: 75%;
}

.imgTelephone {
  width: 60%;
}

.imgMonitor {
  width: 60%;
}

.imgMoney {
  width: 80%;
}

.paddingTitle {
  padding-top: 150px;
}

.boxSelectorWidth {
  width: 65%;
}

@media only screen and (max-width: 901px) {
  .boxCalculatorHome {
    height: 710px !important;
  }

  .circleBox {
    background-color: #FFDFA5;
    border-radius: 300px;
    width: 175px;
    height: 175px;
  }

  .imgCheckListPaper {
    width: 260px;
  }

  .imgFaceBiometry {
    width: 230px;
  }

  .imgOkeyHand {
    width: 270px;
  }

  .whyBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8%;
    height: 22px;
  }

  .whyBoxTopOne {
    padding-top: 120px;
  }

  .whyBoxTop {
    padding-top: 210px;
  }

  .paddingTitle {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 1201px) {
  .boxCalculatorHome {
    background-image: url("../images/home/bannerHome.svg");
    height: 870px;
    width: auto;
    background-size: auto 120%;
    background-repeat: no-repeat;
  }

  .gridHome {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .boxSubtitleHome {
    width: 100%;
  }

  .boxSmallTypography {
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }

  .spaceMobile {
    margin-top: 25px;
  }

  .marginTitleHome {
    padding-top: 40px;
  }

  .flexCircleHome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .flexLastCircleHome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .boxRequirements {
    background-color: #F3F3F3;
    height: 760px;
    text-align: left;
    border-radius: 30px;
    padding: 30px;
  }

  .flexImage {
    display: flex;
    justify-content: center;
  }

  .boxBnpl {
    background-color: #FFDFA5;
    padding: 20px;
    height: 620px;
  }

  .boxWc {
    background-color: #F9CFD4;
    padding: 20px;
    height: 620px;
  }

  .boxForWhat {
    height: 200px;
    border-radius: 42px;
  }

  .secondBoxesImages {
    margin-top: 20px;
  }

  .boxSelectorWidth {
    width: 90%;
  }

  .boxWhyUs {
    height: 760px;
  }
}

@media only screen and (min-width: 1200px) {
  .bannerHome {
    width: 100%;
    height: 630px;
    position: relative;
  }

  .iconBannerHome {
    width: 490px;
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .iconBannerBnpl {
    width: 280px;
  }

  .bannerHomeWt {
    text-align: left;
    width: 95%;
    padding: 5%;
  }

  .bannerHomeImagePhysicalCommerce {
    width: 280px;
  }

  .buttonBannerHome {
    border-radius: 10px !important;
    width: 370px;
  }

  .boxTitleBnpl {
    width: 75%;
    text-align: left;
    margin-top: 3%;
    padding-left: 5%;
  }

  .bannerHomePhysicalCommerceText {
    text-align: left;
    width: 100%;
    padding-bottom: 5%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .boxBannerHomePhysicalCommerce {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12%;
    padding-bottom: 5%;
  }

  .marginCalculator {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 899.99px) {
  .imgMoto {
    width: 150px;
  }
  
  .imgTelephone {
    width: 120px;
  }
  
  .imgMonitor {
    width: 115px;
  }
  
  .imgMoney {
    width: 150px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px)  {
  .imgMoto {
    width: 130px;
  }
  
  .imgTelephone {
    width: 100px;
  }
  
  .imgMonitor {
    width: 105px;
  }
  
  .imgMoney {
    width: 140px;
  }
}

@media only screen and (min-width: 630px) and (max-width: 900px) {
  .boxTextBnpl {
    width: 80%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1370px) {
  .boxHomeBnpl {
    width: 100% !important;
  }

  .boxHomeWc {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1730px) {
  .bannerHome {
    height: 550px !important;
  }

  /* h1 {
    font-size: 170% !important;
  } */

  .textHome {
    font-size: medium !important;
  }

  .iconBannerBnpl {
    width: 210px !important;
  }
}

@media only screen and (max-width: 1359.9px) {
  .bannerHome {
    width: 100%;
    height: 460px;
    position: relative;
  }

  .bannerHomeWt {
    text-align: left;
    width: 95%;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .textBannerHome {
    padding-bottom: 5%;
  }

  .iconBannerHome {
    width: 350px;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 8%;
  }

  .iconBannerBnpl {
    width: 200px;
  }

  .buttonBannerHome {
    border-radius: 10px !important;
    width: 70%;
  }

  .boxTitleBnpl {
    width: 75%;
    text-align: left;
    margin-top: 3%;
    padding-left: 7%;
  }

  .bannerHomePhysicalCommerceText {
    text-align: left;
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .boxBannerHomePhysicalCommerce {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12%;
    padding-bottom: 2%;
  }

  .bannerHomeImagePhysicalCommerce {
    width: 220px !important;
  }

}

@media only screen and (max-width: 600px) {
  .titleMobile {
    font-size: 15pt;
  }

  .css-1sd8qvx-MuiTypography-root{
    font-size: 2.6rem !important;
  }
}

@media only screen and (min-width: 900px) {
  .gridSpaces {
    margin-top: 8%;
  }

  .whyBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }

  .whyBoxInside {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 20px;
    text-align: left;
  }

  /* h1 {
    font-size: 250%;
  } */

  .h2Home {
    text-align: left;
  }

  .h2PaddingLeftHome {
    padding-left: 3%;
  }

  .textHome {
    font-family: 'Open Sans' !important;
    font-size: larger;
    text-align: left;
  }

  .pBoxHome {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .pBoxHomeWhy {
    padding-left: 29.5%;
  }

  .pBoxHomeRequirements {
    justify-content: center;
  }

  .requirementsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}


@media only screen and (max-width: 899.99px) {
  .gridSpaces {
    margin-top: 15%;
  }
  /* h1 {
    font-size: 180%;
  } */

  .h2Home {
    font-size: 95%;
    text-align: left;
  }

  .h2PaddingLeftHome {
    padding-left: 3%;
  }

  .textHome {
    font-family: 'Open Sans' !important;
    font-size: 90%;
    text-align: left;
  }

  .pBoxHome {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10%;
    width: 80%;
  }

  .pBoxHomeRequirements {
    justify-content: center;
  }

  .requirementsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 4%;
  }

  .pRequirements {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.imgCheck {
  width: 25px;
}

.imgTick {
  width: 55px;
}