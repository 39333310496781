.spaceBetween {
  margin-top: 60px;
}

.spaceSubContent {
  margin-top: 40px;
}

.titleUs {
  text-align: center;
}

.imgTeamWork {
  width: 400px;
  padding-top: 30px;
}

.boxValue {
  padding-top: 160px;
  text-align: left;
}

.boxValueIcon {
  padding-top: 130px;
}

.spaceText {
  padding-top: 30px;
}

.alignLeft { 
  text-align: left;
}

.imgRocket {
  height: 280px;
}

.imgBills {
  height: 170px;
}

.imgTrolley {
  height: 200px;
}

.boxCircleUs {
  background-color: #F9CFD4;
  height: 270px;
  width: 270px;
  border-radius: 250px;
}

.boxValueProposal {
  background-image: url("../images/home/fondo-onda-signo-01.svg");
  height: 630px;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 60px;
}

.marginTitleUs {
  padding-top: 30px;
}

.imgCredicuotas {
  width: 120px;
}

.imgBindGroup {
  width: 110px;
}

.imgPoincenot {
  width: 105px;
}

.imgMercadoLibre {
  width: 90px;
}

.imgCnv {
  width: 140px;
}

.imgHonda {
  width: 50px;
}

.imgUberUs {
  width: 40px;
  padding-left: 10px;
}

.boxHistoryIcon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 270px;
  height: 18px;
}

.boxImagesHistory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.boxMercadoLibreIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .spaceBetween {
    margin-top: 40px;
  }
  .spaceSubContent {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1201px) {
  .spacePartners {
    margin-top: 30px;
  }

  .boxCircleUs {
    background-color: #F9CFD4;
    height: 200px;
    width: 200px;
    border-radius: 250px;
  }

  .marginCircle {
    margin-top: 30px;
  }

  .imgRocket {
    height: 205px;
  }
  
  .imgBills {
    height: 120px;
  }
  
  .imgTrolley {
    height: 180px;
  }

  .boxVector {
    background-image: url("../images/us/onda.svg");
    height: 170px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media only screen and (max-width: 700px) {
    .boxVector {
      height: 100px;
    }
  }

  .boxValueProposal {
    background-image: none;
    background-color: #DA0067;
    height: 790px;
    width: auto;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0px;
  }

  .boxValue {
    padding-top: 1px;
    text-align: left;
  }

  .boxValueIcon {
    padding-top: 10px;
  }

  .imgTeamWork {
    width: 250px;
  }

  .widthTitleSmall {
    text-align: center;
  }

  .boxValueProposalMobile {
    background-color: #DA0067;
    height: 500px;
  }

  .boxHistoryIcon {
    width: 120px;
    height: 30px;
    background-color: #DA0067;
  }

  .imgHonda {
    width: 50px;
  }
  
  .imgUberUs {
    width: 50px;    
  }

  .boxMercadoLibreIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 0px;
  }
}