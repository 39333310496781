.bannerRecargaCelular{
    background-color: #f2f2f2;
    width: 100%;
    text-align: center;
    height: 500px;
    padding: 4% 0;
}

.rechargeTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 800;
    color: #383838;
    font-size: 2rem !important;
    width: 100%;
    margin: 0 auto;
}

.rechargeSubtitle{
    font-weight: 600 !important;
    padding-bottom: 3%;
    font-size: 1.1rem;
    color: #383838;
}

.bannerTextRecharge{
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.imgBannerRecargaCelular{
    width: 360px;
}

.ButtonMagentaRecarga{
    font-family: sans-serif;
    align-items: center;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: rgb(242, 2, 93) !important;
    color: rgb(255, 255, 255);
    border: none;
}

.buttonSimulate {
    width: 420px;
}

.ButtonMagentaRecarga:hover{
    background-color: white !important;
    color:  rgb(242, 2, 93);
    border: solid;
    border-color:  rgb(242, 2, 93);
}

.logoTelefonia{
    width: 110px;
    margin: 10px;
}

.stepSubtitle{
    font-weight: bold;
    font-size: 14pt;
    padding-top: 1%;
}

.textIconsrecharge{
    width: 100%;
    font-weight: 400;
}

.boxRecargaCelular{
    width: 460px;
    height: 300px;
    text-align: center;
    margin-top: 1rem;
    padding: 4rem 3rem 1rem;
}

.boxSteprecharge{
    padding-top: 2%;
}

.textContrato{
    padding-top: 20px;
    font-size: 11px;
}

.boxCenter {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 900px){
    .bannerRecargaCelular{
        height: 650px;
    }

    .rechargeTitle{
        font-size: 1.5rem;
        width: 100%;
    }

    .bannerTextRecharge{
        margin-top: 5px;
    }

    .boxButtonRecharge{
        padding: 5% 0;
        text-align: center;
    }

    .ButtonMagentaRecarga{
        width: 70%;
    }

    .imgBannerRecargaCelular{
        width: 200px;
    }

    .boxSteprecharge{
        padding-top: 5%;
    }

    .boxSteprecharge h2{
        font-size: 1.5rem;
    }

    .boxRecargaCelular{
        padding: 1rem 1rem 1rem;
        height: 200px;
    }

    .buttonSimulate {
        width: 300px;
    }

}