.banner{
    background-image: url(../images/banners/bannerWpp.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4% 0;
}

.WppTitle{
    font-family: sans-serif;
    padding-bottom: 2%;
    font-weight: 800;
    color: #383838;
    font-size: 3rem !important;
}

.ButtonVerde{
    font-family: sans-serif;
    align-items: center;
    opacity: 1;
    border-radius: 35px !important;
    height: 3rem;
    line-height: 4.6rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    width: 30%;
    box-shadow: rgb(0 0 0 / 57%) 0px 5px 40px -10px;
    background-color: rgb(61, 178, 92) !important;
    color: rgb(255, 255, 255);
    border: none;
}

.logoWpp{
    margin: 0px 7px;
    cursor: pointer;
}

.textClick{
    padding-top: 2%;
}

.wppBusiness{
    padding: 3% 0
}

.imgIcon{
    width: 2%;
    height: auto;
}

.TextBusiness{
    text-align: center;
    margin: 1rem 0px;
    padding: 0px;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(65, 62, 62) !important;
}

.boxIcons{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    opacity: 1;
    width: 350px;
    height: 250px;
    text-align: center;
    margin-top: 1rem;
    border: 3px solid rgb(255, 252, 252);
    padding: 4rem 3rem 1rem;
}

.centerIcons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.wppIcons{
    width: 20%;
}

.textIcons{
    width: 100%;
    font-weight: 400;
}

@media only screen and (max-width: 900px){

    .banner{
        padding: 8% 0;
    }

    .WppTitle{
        font-size: 2rem !important;
    }

    .ButtonVerde{
        width: 80%;
    }

    .textClick{
        width: 80%;
        margin: 0 auto;
        padding-top: 7%;
    }

    .imgIcon{
        width: 10%;
        padding-top: 5%;
    }

    .TextBusiness{
        font-size: 1.25rem;
        font-weight: 600;
    }

    .boxContainerIcons{
    height: auto;
}


}