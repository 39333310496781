.legalFooterTasasFooter{
    padding: 4% 2%;
    margin-top: 2%;
    text-align: center;
}

.legalFooterTasasText{
    font-size: 10px;
    padding-bottom: 1%;
    text-align: center;
    font-weight: 300;
}

.legalFooterValorCFTEA{
    font-size: 50px;
    margin: 1rem 0px 0px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 1%;
}

.legalFooterIVA{
    font-size: 50px;
    padding-bottom: 1%;
}

.legalFooterValores{
    font-size: 10px;
}